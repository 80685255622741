// src/components/mapbox/api.js
import axios from 'axios';
import config from '../../config.js'; // Import your config file

class Api {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: config.apiHunter,
    });
  }

  async googleAuth(userData) {
    try {
      const response = await axios.post('/google-auth', userData);
      return response.data; 
    } catch (error) {
      console.error('Error during Google Auth:', error);
      throw error; // Re-throw the error to be handled by the component
    }
  }


  async registerUser(userData) {
    try {
      const response = await this.axiosInstance.post('/register', userData);
      return response.data;
    } catch (error) {
      console.error('Error registering user:', error);
      throw error;
    }
  }

  async loginUser(userData) {
    try {
      const response = await this.axiosInstance.post('/login', userData);
      return response.data;
    } catch (error) {
      console.error('Error logging in user:', error);
      throw error;
    }
  }

  async getBroadcastLocations() {
    try {
      const response = await this.axiosInstance.get('/get_broadcast_locations');
      return response.data;
    } catch (error) {
      console.error('Error fetching broadcast locations:', error);
      throw error;
    }
  }

  async getAllUsersAndDevices() {
    try {
      const response = await this.axiosInstance.get('/get_all_users_and_devices');
      return response.data;
    } catch (error) {
      console.error('Error fetching user and device data:', error);
      throw error;
    }
  }

  async deleteAllPositions() {
    try {
      const response = await this.axiosInstance.post('/delete_all_positions');
      return response.data; // Or handle the response as needed
    } catch (error) {
      console.error('Error deleting all positions:', error);
      throw error;
    }
  }

  async addAnimal(animalData) {
    try {
      const response = await this.axiosInstance.post('/add_animal', animalData);
      return response.data;
    } catch (error) {
      console.error('Error adding animal:', error);
      throw error;
    }
  }

  async removeAnimal(animalId) {
    try {
      const response = await this.axiosInstance.delete(`/remove_animal/${animalId}`);
      return response.data;
    } catch (error) {
      console.error('Error removing animal:', error);
      throw error;
    }
  }

  async getAnimals() {
    try {
      const response = await this.axiosInstance.get('/get_animals');
      return response.data;
    } catch (error) {
      console.error('Error fetching animals:', error);
      throw error;
    }
  }

  async getUserData(token) { // Add this method
    try {
      const response = await this.axiosInstance.get('/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
  }

  async editUserName(name, token) { // Add this method
    try {
      const response = await this.axiosInstance.put('/user_edit_name', { UserName: name }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating user name:', error);
      throw error;
    }
  }

  async uploadUserPhoto(file, token) { // Add this method
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.axiosInstance.post('/upload_user_photo', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading user photo:', error);
      throw error;
    }
  }
}

const api = new Api();
export default api;