import React, { useState, useEffect } from "react";
import api from "./api";

const UserData = () => {
    const [usersData, setUsersData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const data = await api.getAllUsersAndDevices();
                setUsersData(data);
            } catch (error) {
                console.error("Error fetching user and device data:", error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    if (isLoading) {
        return <div>Loading user data...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div id="userData">
            <h2>User and Device Data</h2>
            <ul id="userListItems">
                {usersData.map((user) => (
                    <li key={user.userId}>
                        <details>
                            <summary>
                                <strong>User:</strong> {user.userName} (Email: {user.userEmail}) (ID: {user.userId})
                            </summary>
                            {user.devices && user.devices.length > 0 ? (
                                <ul>
                                    {user.devices.map((device) => (
                                        <li key={device.deviceId}>
                                            <details>
                                                <summary>
                                                    <strong>Device:</strong> {device.deviceName} (ID:{" "}
                                                    {device.deviceId})
                                                </summary>
                                                {device.positions && device.positions.length > 0 ? (
                                                    <ul>
                                                        {device.positions.map((position, index) => (
                                                            <li key={index}>
                                                                <details>
                                                                    <summary>
                                                                        Latitude: {position.latitude}, Longitude:{" "}
                                                                        {position.longitude}, Timestamp:{" "}
                                                                        {position.timestamp}
                                                                    </summary>
                                                                    {/* You can add more details here if needed */}
                                                                </details>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <p>No positions found for this device.</p>
                                                )}
                                            </details>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No devices found for this user.</p>
                            )}
                        </details>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default UserData;