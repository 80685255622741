import React, { useState, useEffect } from 'react';
import api from '../components/mapbox/api.js'; // Import the API class
import '../scss/component/_section.scss';
import '../scss/component/_box.scss';
import '../scss/component/_tf-section.scss';
import '../scss/component/_reponsive.scss';

import Locations from '../components/locations/index.jsx';

import Map from '../components/mapbox/Map3';
import TopBar from '../components/mapbox/TopBar';
import UserData from '../components/mapbox/UserData';
import SpaceTopBar from '../components/SpaceTopBar/index.jsx';

function HomePrivate(props) {
    const [broadcastLocations, setBroadcastLocations] = useState([]);
    const [username, setUsername] = useState(localStorage.getItem('UserEmail') || '');
    const [isLoading, setIsLoading] = useState(true); // Add loading state

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (!storedToken) {
            window.location.href = '/';
        } else {
            // Fetch BroadcastLocation data using the API class
            const fetchData = async () => {
                try {
                    const locationsData = await api.getBroadcastLocations();
                    // Update the state with the fetched data
                    setBroadcastLocations(locationsData);

                    // Log both locationsData and broadcastLocations after the state update
                    console.log("locationsData:", locationsData);
                    console.log("broadcastLocations:", broadcastLocations);
                } catch (error) {
                    // Handle errors here (e.g., display an error message)
                    console.error('Error fetching data:', error);
                } finally {
                    setIsLoading(false); // Set loading to false after data is fetched or error occurs
                }
            };

            fetchData();
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('UserEmail');
        window.location.href = '/'; // Redirect after logout
    };

    const handleDeleteAllPositions = () => {
        if (confirm("Are you sure you want to delete all historical positions?")) {
            api.deleteAllPositions() // Assuming you add this method to your API class
                .then(() => {
                    alert("All positions deleted successfully!");
                    // Refresh data after deletion
                    return api.getBroadcastLocations();
                })
                .then(locationsData => {
                    setBroadcastLocations(locationsData);
                })
                .catch(error => {
                    console.error('Error deleting positions:', error);
                    alert('An error occurred while deleting positions.');
                });
        }
    };

    return (
        <div className='header-fixed main home1 counter-scroll'>
            <SpaceTopBar />
            <TopBar username={username} onLogout={handleLogout} />

            {isLoading ? ( // Conditionally render loading indicator
                <div>Loading map data...</div>
            ) : (
                <Map userBroadcastLocations={broadcastLocations} />
            )}

            <UserData />

            <button id="deleteAllPositionsButton" onClick={handleDeleteAllPositions}>
                Delete All Historical Positions
            </button>
            {/*
            <Locations />
            */
            }

        </div>
    );
}

export default HomePrivate;