import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App.js';
import AppPublic from './AppPublic.js';
import './App.scss'
import ScrollToTop from "./ScrollToTop";


const isLoggedIn = !!localStorage.getItem('token'); // Check for token


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

  <React.Fragment>
  <BrowserRouter>
  <ScrollToTop />

{
isLoggedIn ? <App /> : <AppPublic />
} 


  </BrowserRouter>
</React.Fragment>
  </React.StrictMode>,
);


