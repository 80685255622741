import {React , useEffect} from 'react';
import { Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import routes from './pages/indexPublic';
import '../src/assets/fonts/font-awesome.css'
import Header from './components/headerPublic';
import Footer from './components/footerPublic';

function AppPublic() {
  useEffect(() => {
    AOS.init({
      duration : 2000
    }); 
  }, []);
  return (
    <>

    <Header />

    <Routes>

        {
            routes.map((data,idx) => (
                <Route key={idx} path={data.path} element={data.component} exact />
            ))
        }

    </Routes>

    <Footer />
</>
  );
}

export default AppPublic;