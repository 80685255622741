import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = 'pk.eyJ1Ijoid2VlYnUiLCJhIjoiY20wZHJ4MGxuMGN3czJqc2RiajNhY2t6cyJ9.C0ia-l1E1jdXRgD3lUCKsg';

const Map = ({ userBroadcastLocations }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);

    useEffect(() => {
        if (map.current) return;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [5.0939316, 61.7476622],
            zoom: 10,
        });

        map.current.on('load', () => {
            map.current.markers = []; // Initialize markers array

            // Add initial markers (only once)
            const bounds = new mapboxgl.LngLatBounds();
            userBroadcastLocations.forEach((user) => {
                if (user.devices && user.devices.length > 0) {
                    user.devices.forEach((device) => {
                        const lastPosition = device.positions[device.positions.length - 1];
                        if (lastPosition && lastPosition.latitude && lastPosition.longitude) {
                            const marker = new mapboxgl.Marker({ deviceId: device.deviceId })
                                .setLngLat([lastPosition.longitude, lastPosition.latitude])
                                .setPopup(new mapboxgl.Popup().setHTML(`
                  <strong>User:</strong> ${user.userName}<br>
                  <strong>Device ID:</strong> ${device.deviceId}
                `))
                                .addTo(map.current);
                            map.current.markers.push(marker);

                            bounds.extend([lastPosition.longitude, lastPosition.latitude]);
                        }
                    });
                }
            });

            if (userBroadcastLocations.length > 0 && bounds.isEmpty()) {
                console.warn('No valid client locations found to fit map bounds.');
            } else if (userBroadcastLocations.length > 0) {
                map.current.fitBounds(bounds, { padding: 50, duration: 1000 });
            }

            // Call updateDeviceLayers initially and whenever userBroadcastLocations changes
            updateDeviceLayers();
        });

        // Update only the device layers when userBroadcastLocations changes
        const updateDeviceLayers = () => {
            userBroadcastLocations.forEach((user) => {
                if (user.devices && user.devices.length > 0) {
                    user.devices.forEach((device) => {
                        const coordinates = device.positions.map((pos) => [
                            pos.longitude,
                            pos.latitude,
                        ]);

                        if (coordinates.length > 0) {
                            // Update the source data for the device
                            let source = map.current.getSource(device.deviceId);
                            if (source) {
                                source.setData({
                                    type: "Feature",
                                    properties: {},
                                    geometry: {
                                        type: "LineString",
                                        coordinates: coordinates,
                                    },
                                });
                            } else {
                                // If the source doesn't exist, add it (should only happen initially)
                                map.current.addSource(device.deviceId, {
                                    type: "geojson",
                                    data: {
                                        type: "Feature",
                                        properties: {},
                                        geometry: {
                                            type: "LineString",
                                            coordinates: coordinates,
                                        },
                                    },
                                });

                                // Also add the layer if the source is newly added
                                map.current.addLayer({
                                    id: `route-${device.deviceId}`,
                                    type: "line",
                                    source: device.deviceId,
                                    layout: {
                                        "line-join": "round",
                                        "line-cap": "round",
                                    },
                                    paint: {
                                        "line-color": "#888",
                                        "line-width": 4,
                                        "line-opacity": 0.8,
                                    },
                                });
                            }
                        }
                    });
                }
            });
        };



        // Clean up the map instance when the component unmounts
        return () => {
            if (map.current) {
                map.current.remove();
                map.current = null;
            }
        };
    }, [userBroadcastLocations]);

    return <div ref={mapContainer} style={{ width: "100%", height: "800px" }} />;
};

export default Map;