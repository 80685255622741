import React, { useState } from 'react';
import api from '../components/mapbox/api'; // Import the API class
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../images/bg.jpeg'; // Adjust the path if needed
import LoginGoogle from './LoginGoogle';


import CTA from '../components/cta';
import { Link } from 'react-router-dom';

function Register(props) {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState(''); 
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); 
 

  const handleSubmit = async (e) => {
    e.preventDefault();


    try {
      const response = await api.registerUser({ 
        UserEmail: email,
        UserName: username, 
        UserPassword: password,
      });
  
      // Handle successful registration (e.g., display a success message or redirect to login)
      console.log('Registration successful:', response);
      navigate('/login'); 
    } catch (error) {
      // Handle registration error (e.g., display an error message)
      console.error('Registration failed:', error);
    }
  };

  
    return (
        <div className='inner-page'>
            <section className="page-title">
            <div className="overlay"></div> 
        </section>

        <section className="tf-section project-info" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="container"> 
            <div className="row">
                <div className="col-md-12">
                <form onSubmit={handleSubmit}>
                        <div className="project-info-form form-login style2">
                            <h6 className="title">Register</h6>
                            <h6 className="title show-mobie"><Link to="/login">Login</Link></h6>
                            <h6 className="title link"><Link to="/login">Login</Link></h6>
                            <p>Welcome to Hunter Overpass, please enter your details</p>
                            <div className="form-inner">
                    <fieldset>
                      <label>Email address *</label>
                      <input
                        type="email"
                        placeholder="Your email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </fieldset>
                    <fieldset>
                      <label>Username *</label> 
                      <input
                        type="text"
                        placeholder="Your username"
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </fieldset>
                    <fieldset>
                      <label>Password *</label>
                      <input
                        type="password"
                        placeholder="Your password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </fieldset>


                    {/* ... your existing code ... */}
                    </div>
                  {/* ... your existing code ... */}
                </div>

                <LoginGoogle /> {/* Add the Google Sign-In button */}

                <div className="wrap-btn">
                  <button type="submit" className="tf-button style2">
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>


            

            
            </div>
  );
}

export default Register;