// src/components/mapbox/TopBar.js
import React from 'react';

const TopBar = ({ username, onLogout }) => {
  return (
    <div id="topBar">
      <div id="userInfo">
        <p>Welcome, <span id="username">{username}</span>!</p>
      </div>
      <a 
        href="/api/hunter/app/android" 
        download="app-release.apk" 
        className="download-button"
      >
        Download App
      </a>
      <button id="logoutButton" onClick={onLogout}>
        Logout
      </button>
    </div>
  );
};

export default TopBar;